/* Please see documentation at https://docs.microsoft.com/aspnet/core/client-side/bundling-and-minification
for details on configuring this project to bundle and minify static web assets. */

@font-face {
    font-family: "Calibri";
    font-weight: normal;
    src: url("./fonts/calibri-regular.ttf");
    src: local(""), url("./fonts/calibri-regular.ttf") format("truetype");
}

@font-face {
    font-family: "Calibri";
    font-weight: bold;
    src: url("./fonts/calibri-bold.ttf");
    src: local(""), url("./fonts/calibri-bold.ttf") format("truetype");
}

@font-face {
    font-family: "Calibri";
    font-style: italic;
    src: url("./fonts/calibri-italic.ttf");
    src: local(""), url("./fonts/calibri-italic.ttf") format("truetype");
}

html,
body {
    overflow: hidden;
    position: relative;
    height: 100%;
}

#root {
    overflow: hidden;
    height: 100%;
}

.app {
    overflow: hidden;
    height: 100%;
}

:root {
    /* Colors: */
    --unnamed-color-fef2e9: #fef2e9;
    --unnamed-color-fcc8a9: #fcc8a9;
    --unnamed-color-e0e0df: #e0e0df;
    --unnamed-color-9e9590: #9e9590;
    --unnamed-color-5a9cd3: #5a9cd3;
    --unnamed-color-ffffff: #ffffff;
    --unnamed-color-000000: #000000;
    --unnamed-color-191919: #191919;
    --unnamed-color-393939: #393939;

    /* Font/text values */
    --unnamed-font-family-calibri: Calibri;
    --unnamed-font-style-normal: normal;
    --unnamed-font-weight-bold: bold;
    --unnamed-font-weight-normal: normal;
    --unnamed-font-size-15: 15px;
    --unnamed-font-size-16: 16px;
    --unnamed-font-size-18: 18px;
    --unnamed-font-size-19: 19px;
    --unnamed-font-size-20: 20px;
    --unnamed-font-size-22: 22px;
    --unnamed-font-size-30: 30px;
    --unnamed-font-size-36: 36px;
    --unnamed-font-size-44: 44px;
    --unnamed-character-spacing-0: 0px;
    --unnamed-character-spacing-0-32: 0.32px;
    --unnamed-character-spacing-0-2: 0.2px;
    --unnamed-line-spacing-17: 17px;
    --unnamed-line-spacing-21: 21px;
    --unnamed-line-spacing-23: 23px;
    --unnamed-line-spacing-27: 27px;
    --unnamed-line-spacing-28: 28px;
    --unnamed-line-spacing-32: 32px;
    --unnamed-line-spacing-34: 34px;
    --unnamed-line-spacing-51: 51px;
    --unnamed-line-spacing-63: 63px;
    --unnamed-text-transform-uppercase: uppercase;
}
/* Character Styles */
.cta {
    font-family: var(--unnamed-font-family-calibri);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--unnamed-font-size-20);
    line-height: var(--unnamed-line-spacing-27);
    letter-spacing: var(--unnamed-character-spacing-0-2);
    color: var(--unnamed-color-ffffff);
}
.unit {
    font-family: var(--unnamed-font-family-calibri);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--unnamed-font-size-16);
    line-height: var(--unnamed-line-spacing-23);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-9e9590);
}
.radio-button {
    font-family: var(--unnamed-font-family-calibri);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-16);
    line-height: var(--unnamed-line-spacing-23);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-000000);
}
.input {
    font-family: var(--unnamed-font-family-calibri);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-18);
    line-height: var(--unnamed-line-spacing-27);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-000000);
}
.label {
    font-family: var(--unnamed-font-family-calibri);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--unnamed-font-size-15);
    line-height: var(--unnamed-line-spacing-21);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-393939);
}
.section {
    font-family: var(--unnamed-font-family-calibri);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--unnamed-font-size-16);
    line-height: var(--unnamed-line-spacing-23);
    letter-spacing: var(--unnamed-character-spacing-0-32);
    color: var(--unnamed-color-5a9cd3);
    text-transform: var(--unnamed-text-transform-uppercase);
}
.body-copy {
    font-family: var(--unnamed-font-family-calibri);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-18);
    line-height: var(--unnamed-line-spacing-27);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-191919);
    white-space: pre-line;
}
.headline-s {
    font-family: var(--unnamed-font-family-calibri);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--unnamed-font-size-22);
    line-height: var(--unnamed-line-spacing-32);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-393939);
}
.headline-m {
    font-family: var(--unnamed-font-family-calibri);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-36);
    line-height: var(--unnamed-line-spacing-51);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-191919);
}
.headline-l {
    font-family: var(--unnamed-font-family-calibri);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-44);
    line-height: var(--unnamed-line-spacing-63);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-191919);
}
.headline-ml {
    font-family: var(--unnamed-font-family-calibri);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-36);
    line-height: var(--unnamed-line-spacing-51);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-191919);
}
.headline-15 {
    font-family: var(--unnamed-font-family-calibri);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--unnamed-font-size-15);
    line-height: var(--unnamed-line-spacing-21);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-393939);
}
.headline-15-caps {
    font-family: var(--unnamed-font-family-calibri);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--unnamed-font-size-15);
    line-height: var(--unnamed-line-spacing-21);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-393939);
    text-transform: uppercase;
}
.body-m {
    font-family: var(--unnamed-font-family-calibri);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-18);
    line-height: var(--unnamed-line-spacing-27);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-191919);
}
.headline-15-black {
    font-family: var(--unnamed-font-family-calibri);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--unnamed-font-size-15);
    line-height: var(--unnamed-line-spacing-21);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-000000);
}
.bold-link {
    font-family: var(--unnamed-font-family-calibri);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--unnamed-font-size-18);
    line-height: var(--unnamed-line-spacing-27);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-191919);
    text-decoration: underline;
    text-transform: none;
    margin-right: 8px;
}
.bold-link-confirm {
    font-family: var(--unnamed-font-family-calibri);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--unnamed-font-size-18);
    line-height: var(--unnamed-line-spacing-27);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    margin-right: 8px;
}
.user-name {
    font-family: var(--unnamed-font-family-calibri);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-18);
    line-height: var(--unnamed-line-spacing-27);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
}
.unit-spacing {
    font-family: var(--unnamed-font-family-calibri);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--unnamed-font-size-16);
    line-height: var(--unnamed-line-spacing-23);
    letter-spacing: var(--unnamed-character-spacing-0-2);
    color: var(--unnamed-color-5a9cd3);
    text-transform: uppercase;
}
.download-link {
    font-family: var(--unnamed-font-family-calibri);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--unnamed-font-size-15);
    line-height: var(--unnamed-line-spacing-21);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-000000);
    text-decoration: underline;
}
.overline-1 {
    font-family: var(--unnamed-font-family-calibri);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-19);
    line-height: var(--unnamed-line-spacing-27);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-191919);
    text-transform: uppercase;
}
.text-header {
    font-family: var(--unnamed-font-family-calibri);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--unnamed-font-size-30);
    line-height: var(--unnamed-line-spacing-34);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-000000);
    font-family: "Courier New", Courier, monospace;
}
.index-button {
    font-family: var(--unnamed-font-family-calibri) !important;
    font-style: var(--unnamed-font-style-normal) !important;
    font-weight: var(--unnamed-font-weight-bold) !important;
    font-size: var(--unnamed-font-size-20) !important;
    line-height: var(--unnamed-line-spacing-28) !important;
    letter-spacing: var(--unnamed-character-spacing-0) !important;
    color: var(--unnamed-color-000000) !important;
    padding: 15px 60px !important;
    text-transform: unset !important;
    margin: 10px !important;
    background-color: #fff !important;
}
.nav-link {
    font-family: var(--unnamed-font-family-calibri);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--unnamed-font-size-18);
    line-height: var(--unnamed-line-spacing-27);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    text-decoration: underline;
    display: flex;
    justify-content: center;
    margin-left: 25px;
    margin-right: 25px;
}
.text-link {
    font-family: var(--unnamed-font-family-calibri);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--unnamed-font-size-18);
    line-height: var(--unnamed-line-spacing-27);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-5a9cd3);
}
.nav-logo {
    display: flex;
    justify-content: center;
    margin-right: 25px;
}
.nav-menu {
    font-family: var(--unnamed-font-family-calibri);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--unnamed-font-size-18);
    line-height: var(--unnamed-line-spacing-27);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    text-decoration: underline;
    text-transform: none;
    display: flex;
    justify-content: center;
    margin-left: 25px;
    margin-right: 25px;
}
.logout-button {
    font-family: var(--unnamed-font-family-calibri);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--unnamed-font-size-18);
    line-height: var(--unnamed-line-spacing-27);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    text-decoration: underline;
    display: flex;
    justify-content: center;
}
.unit-blue {
    font-family: var(--unnamed-font-family-calibri);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--unnamed-font-size-16);
    line-height: var(--unnamed-line-spacing-16);
    color: var(--unnamed-color-5a9cd3);
    margin-left: 8px;
}

a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.border-top {
    border-top: 1px solid #e5e5e5;
}

.border-bottom {
    border-bottom: 1px solid #e5e5e5;
}

.height-20 {
    height: 20px;
}

.height-40 {
    height: 40px;
}

.box-shadow {
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.request-history {
    max-height: 404px;
    overflow-y: auto;
    flex: 1;
}

.styled-box {
    margin: 0 !important;
}

button.accept-policy {
    font-size: 1rem;
    line-height: inherit;
}

.center {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.centerV {
    display: flex;
    align-items: center;
}

.centerH {
    display: flex;
    flex: 1;
    justify-content: center;
}

.budget-warning {
    background-color: #c7672d;
    padding: 0 11px 0 8px;
}

.budget-cell {
    display: inline-flex;
    align-items: center;
}

.display-flex {
    display: flex;
}

.color-white {
    color: #fff;
}

.color-393939 {
    color: #393939;
}

.cursor-pointer {
    cursor: pointer;
}

.section-padding {
    padding-bottom: 30px;
}

.input-padding {
    padding-bottom: 20px;
}

.commentInput {
    background-color: #ffffff !important;
}

.commentTextField {
    width: 100%;
}

.commentTextField .MuiFilledInput-multiline {
    padding: 12px;
}

.warning {
    color: #c7672d;
}

.padding-right-5 {
    padding-right: 5px;
}

.padding-right-10 {
    padding-right: 10px;
}

.padding-right-11 {
    padding-right: 11px;
}

.courier-bold {
    font-family: "Courier New", Courier, monospace;
    font-weight: bold;
}

.form-error {
    margin-top: 5px;
    margin-left: 5px;
    color: #f44336;
    font-size: 0.75rem;
}
